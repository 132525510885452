import { GatsbySeo } from 'gatsby-plugin-next-seo'

import BlackFriday from '../../components/Campanha/BlackFriday'

function BlackFridayPage() {
  return (
    <>
      <GatsbySeo
        title="Black Friday 2022 na Decathlon - Ofertas até 50% de Desconto"
        noindex
        nofollow
        description="Black Friday 2022 na Decathlon está chegando! Compre roupas, calçados, equipamentos, acessórios, artigos esportivos com até 50% de desconto."
      />
      <BlackFriday />
    </>
  )
}

export default BlackFridayPage
